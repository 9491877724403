.QsDiv{
    color: white;
    background-color: none;
    font-size: 100px;
    width: 116%;
    /* background-color: rgb(128, 0, 0); */
}

.QsButtons{
    margin: 0%;
    background: none;
    width: 100%;
    border: none;
    /* background-color: rgb(128, 0, 0); */
}





.Watch{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 85px;
    font-weight: bold;
    color: #b11010;
    background: rgb(0, 0, 0);
  box-shadow: 0px 0px 52px 13px rgba(0, 0, 0, 0.50);
  backdrop-filter: blur(9.5px);
    padding: 20px;
    border-radius: 30px;
    position: relative;
    z-index: -1;
    text-align: center;
    min-width: 500px;
    cursor:auto;
  }