

.reg {
    color: white;
    /* font-family: cabin; */
    font-size: 6vw;
    font-weight: lighter;
    letter-spacing: 15px;
    text-align: center;
    animation: glitch1 2.5s infinite;
    /* z-index: 200; */
  
    font-family: macer, monospace;
    text-shadow: 0 5px 3px #000000;
  }
  
  
  
  
  .sponsors{
    width: 100%;
    background-color: #00000a;
  
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
    font-family: macer;
    font-size: 30px;
    letter-spacing: 5px;
  
    position: relative;
  }
  
  @media only screen and (max-width: 600px){
    .sponsorhead{
      font-size: 25px;
    }
  
    .sponsorheads{
      font-size: 35px;
    }
    
    .sponsor{
      scale: 0.9;
    }
  }
  
  .sponsor{
    background-color: #ececec;
    border-radius: 10px;
    height: 150px;
    width: 250px;
  
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  
    transition-duration: 200ms;
  
    margin: 10px 20px;
  }
  
  .sponsor:hover > img{
    opacity: 0;
  }
  
  .sponsor > p{
    opacity: 0;
    position: absolute;
    letter-spacing: normal;
    line-height:normal;
    font-size: 12px;
    text-align: center;
  
    color: #00000a;
  
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: sans-serif;
  
    height: inherit;
    width: inherit;
  
    padding: 0px 15px;
    
  }
  
  .sponsor:hover > p{
    opacity: 100;
  }
  
  
  div.sponsor > img{
    max-width: 90%;
    max-height: 90%;
    transition-duration: 200ms;
  }
  
  .ml{
    margin-left: 100px;
  }
  
  .sponsrow{
    display: flex;
    flex-wrap: wrap;
    margin: 50px 0;
  
    align-items: center;
    justify-content: center;
  }
  
  .d-flex{
    display: flex;
  }
  
  .flex-column{
    display: flex;
    flex-direction: column;
    width: max-content;
  
    align-items: center;
  }
  
  .flex-wrap{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  
    justify-content: center;
  }
  
  .sponsorcont{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 70%;
  }
  
  h3, h1{
    text-align: center;
  }
  
  .seperator{
    width: 70%;
  }
  
  .w-50{
    width: 50%;
  }
  
  .w-90{
    width: 90%;
  }