.leaderboardcont{
    width: 70%;
    height: 500px;

    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;

    overflow-y: auto;
}

.groupswitch{
    width: 70%;

    font-family: audio;

    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 67px;
    margin-bottom: 43px;
}

.groupswitch > button {
    padding: 5px 20px;

    border-radius: 0;

    background: transparent;
    border: 0px solid;

    margin: 0px 10px;
}

.groupswitch > button:hover::after{
    background-color: rgb(0, 37, 20);
    transform: skewX(-30deg)
}

.Submit-btn:hover{
    background: none
}

.groupswitch > button::after {
    content: '';
    width: 100%;
    height: 100%;
    background-color: aqua;
    position: absolute;
    inset: 0;
    
    padding: 5px 20px;

    border-radius: 0;

    background: rgba(0, 0, 0, 0.251);
    backdrop-filter: blur( 3px );
    -webkit-backdrop-filter: blur( 2px );
    border: 2px solid rgb(0, 255, 106);

    transform: skewX(-30deg);
    z-index: -1;
}

p, h5{
    margin: 0;
    padding: 0;
}

.team{
    position: relative;
    width: 90%;

    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 15px;
    margin-bottom: 5px;

    padding: 12px 0;
}

.team:last-child{
    margin-bottom: 0px;
}

.team::after{
    content: '';
    width: 100%;
    height: 100%;
    background-color: aqua;
    position: absolute;

    background: rgba(24, 24, 24, 0.251);
    backdrop-filter: blur( 3px );
    -webkit-backdrop-filter: blur( 2px );
    border: 1px solid rgb(0, 217, 255);
    box-shadow: 0 0 10px 5px rgb(0, 31, 36);

    border-radius: 7px;

    z-index: -1;

    transform: skewX(-30deg)
}

.rank{
    width: 10%;
    text-align: center;
}

.teamname{
    width: 40%;
    text-align: center;
}

.pts{
    width: 15%;
    text-align: center;
}
.time{
    width: 35%;
    text-align: center;
}

.content{
    padding:0;
}