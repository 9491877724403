.leaderboardcont{
    margin-top: 8%;
    width: 70%;

    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;

}

.member{
    position: relative;
    width: 90%;

    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 15px;
    margin-bottom: 5px;

    padding: 12px 0;
}

.member:last-child{
    margin-bottom: 0px;
}

.member::after{
    content: '';
    width: 100%;
    height: 100%;
    background-color: aqua;
    position: absolute;

    background: rgba(24, 24, 24, 0.251);
    backdrop-filter: blur( 3px );
    -webkit-backdrop-filter: blur( 2px );
    border: 1px solid rgb(0, 217, 255);
    box-shadow: 0 0 10px 5px rgb(0, 31, 36);

    border-radius: 7px;

    z-index: -1;

}

.Name{
    display: inline;
    width: 50%;
    font-size: 25px;
    /* margin-left: 20px; */

}

.discord{
    width: 40%;
    font-size: 22px;
    text-align: center;
    /* margin-left: 10%; */
}

.mem{
    margin-left: 30px;
}


.role{
    margin-left: 20px;
    margin-top: 3%;
    font-weight: 100;
    font-size: 17px;
    color: rgba(255, 255, 255, 0.555);
}