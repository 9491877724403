.commonrulebg{
    background-image: linear-gradient(to bottom, rgba(0, 0, 0), rgba(0, 0, 0, 0.5), rgba(0, 0, 0)), url("./cmap.jpg");
    background-size: cover;
}

.content{
    width: calc(100% - 100px);
    display: flex;
    flex-direction: column;
}

.rules{
    height: 450px;
    width: 750px;
    border-radius: 20px;
    background: rgba(24, 24, 24, 0.251);
    backdrop-filter: blur( 5px );
    -webkit-backdrop-filter: blur( 2px );
    border: 1px solid rgba(255, 255, 255, 0.05);
    box-shadow: 0 0 20px 0px black;
    padding: 30px;

    z-index: 100;
}



.RulebookCont{
    text-align: justify;
    padding-right: 2%;
    height:350px;
    overflow-y: auto;
}
.RulebookCont::-webkit-scrollbar {
    width: 8px;
  }
  
  .RulebookCont::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  .RulebookCont::-webkit-scrollbar-thumb {
    background-color: #d3d3d3;
    border-radius: 4px;
  }
  
  .RulebookCont::-webkit-scrollbar-thumb:hover {
    background-color: #a9a9a9;
  }

p{
    text-align: justify;
    color: white;
}



.shortcutsR{
    display: flex;
    justify-content: space-around   ;
    align-items: center;
}

.shortcutsR>h1{
    color: white;
    font-size: 20px;
}