body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;

  /* overflow-y: hidden; */
  /* overflow-x: hidden */
}

a{
  text-decoration: none;
  color: inherit;
}

@font-face {
  font-family: audio;
  src: url("../public/Audiowide.ttf");
}

@font-face {
  font-family: nusar;
  src: url("../public/Nusar.ttf");
}
.audio{
  font-family: audio, sans-serif;
}

.nusar{
  font-family: nusar, sans-serif;
}


.wrapper{
  display: flex;
}


::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #1b1b1b;
  border-radius: 100px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #008cff;
  border-radius: 100px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0058a0;
}
.loading {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: 10px solid #00A1B1;
  border-top: 10px solid #1D1C2D;
  animation: spin 1s linear infinite;
}
.notifications{
  z-index: 300000;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}