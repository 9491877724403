.popup {
    width: 30%;
    height: auto;
    border-radius: 12px;
    background: rgba(24, 24, 24, 0.40);
    box-shadow: 0px 0px 52px 13px rgba(0, 0, 0, 0.50);
    backdrop-filter: blur(9.5px);
    text-align: center;
    font-family: 'Audiowide', sans-serif;
    color: #fff;
    overflow: hidden;
    z-index: 20000000000;
    position: absolute;
    top:0;
    left:0;
    margin-top: 14%;
    margin-left: 35%;
    padding-bottom: 4%;
  }
  

.leaderboardcont{
    width: 90%;
    height: max-content;
    text-align: justify;
    margin-top: 8%;
    padding-left: 2.5%;
    margin-left: 5%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
    overflow-y: auto; /* Add scroll bar to the popup content */
    height: 35vh;
}


.leaderboardcont::-webkit-scrollbar {
    width: 8px;
  }
  
  .leaderboardcont::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  .leaderboardcont::-webkit-scrollbar-thumb {
    background-color: #d3d3d3;
    border-radius: 4px;
  }
  
  .leaderboardcont::-webkit-scrollbar-thumb:hover {
    background-color: #a9a9a9;
  }
  


 h5{
    margin: 0;
    padding: 0;
}

.team{
    position: relative;
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 1.5%;
    padding: 12px 0 12px 0;
}

.team::after{
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.89);
    position: absolute;

    background: rgba(24, 24, 24, 0.251);
    backdrop-filter: blur( 3px );
    -webkit-backdrop-filter: blur( 2px );
    border: 1px solid rgb(255, 255, 255);
    box-shadow: 0 0 20px 2px rgba(255, 255, 255, 0.137);

    border-radius: 7px;

    z-index: -1;

}

.rank{
    width: 8%;
    font-size:17px;
    text-align: center;
}

.teamname{
    width: 47%;
    font-size:16px;
    font-weight:100 ;
    text-align: center;
}

.pts{
    width: 20%;
    font-size:17px;
    text-align: center;
}
.time{
    width: 24%;
    padding-right: 1%;
    font-size:16px;
    text-align: center;
}