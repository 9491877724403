.errorpage{
    height: 100vh;
    width: 100vw;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.2), rgba(0, 0, 0,0.8), rgba(0, 0, 0, 1)), url("./err.jpg");
    background-size: cover;

    display: flex;
    align-items: center;
    flex-direction: column;
}

.errorpage > h1{
    font-family: audio;

    text-shadow: 0 2px 10px black;
}

.back{
    margin-top: 17vh;
    font-family: audio;
    text-shadow: 0 2px 10px black;  
    margin-bottom: 23vh; 
}