.topbar{
    width: 100%;
    height: 95px;
    /* background-color: aquamarine; */

    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    padding: 0px 50px;
}