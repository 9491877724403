@import url('https://fonts.googleapis.com/css2?family=Audiowide&family=Roboto+Flex:wght@200&display=swap');
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css");


.UnivBody {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}



.popup {
  width: 30%;
  height: auto;
  flex-shrink: 0;
  border-radius: 12px;
  background: rgba(24, 24, 24, 0.40);
  box-shadow: 0px 0px 52px 13px rgba(0, 0, 0, 0.50);
  backdrop-filter: blur(9.5px);
  text-align: center;
  font-family: 'Audiowide', sans-serif;
  color: #fff;
  overflow: hidden;
  z-index: 20000000000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top:0;
  left:0;
  margin-top: 14%;
  margin-left: 35%;
  padding-bottom: 2%;
}

    .popupHeading {
      margin-top: 40px;
      font-size: 30px;
    }

    .popupContent {
      margin: 20px;
      font-size: 20px;
      font-family: 'Roboto Flex', sans-serif;
      padding: 0 20px; /* Add padding to create a gap */
      line-height: 1.5;
      overflow-y: auto; /* Add scroll bar to the popup content */
      height: 200px; /* Set height to fill the available space */
    }

    .popupContent::-webkit-scrollbar {
      width: 8px;
    }

    .popupContent::-webkit-scrollbar-thumb {
      background: #d3d3d3;
      border-radius: 4px;
    }

    .popupContent::-webkit-scrollbar-thumb:hover {
      background: #a9a9a9;
    }

    .popupClose {
      position: absolute;
      cursor: pointer;
      color: #FFF;
      font-size: 20px;
      top: 0;
      right: 0;
      margin: 2% 3% 0 0;
    }
    






    
.Loader {
  height: 400px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: 10px solid #0b851f9c;
  border-top: 10px solid #1D1C2D;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
      transform: rotate(0deg);
  }

  100% {
      transform: rotate(360deg);
  }
}