@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css");
@import url('https://fonts.googleapis.com/css2?family=Audiowide&family=Roboto+Flex:wght@200&display=swap');



.popup {
  width: 40%;
  flex-shrink: 0;
  border-radius: 12px;
  background: rgba(24, 24, 24, 0.40);
  box-shadow: 0px 0px 52px 13px rgba(0, 0, 0, 0.50);
  backdrop-filter: blur(9.5px);
  text-align: center;
  font-family: 'Audiowide', sans-serif;
  color: #fff;
  overflow: hidden;
  z-index: 20000000000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top:0;
  left:0;
  margin-top: 26vh;
  margin-left: 30%;
  padding-bottom: 2%;
}

.popupClose {
  position: absolute;
  cursor: pointer;
  color: #FFF;
  font-size: 20px;
  top: 0;
  right: 0;
  margin: 2% 3% 0 0;
}


.popupHeading {
  flex-shrink: 0;
  color: #FFF;
  text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  font-family: 'Audiowide', sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 2.15px;
  margin: 0 auto;
  margin-top: 50px;
}

.popupQuestion {
  color: #FFF;
  text-align: justify;
  font-family: 'Roboto Flex', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal; 
  letter-spacing: 1.25px;
  margin-top: 30px;
  padding: 0 40px;
  overflow-y: auto; /* Add scroll bar to the popup content */
  height: 200px; /* Set height to fill the available space */
  width: 90%;
}

.popupDescription {
  font-size: 15px;
  font-weight: 100;
  text-align: justify;
  width: 90%;
  margin-top: 3%;
}

.popupQuestion::-webkit-scrollbar {
  width: 8px;
}

.popupQuestion::-webkit-scrollbar-track {
  background-color: transparent;
}

.popupQuestion::-webkit-scrollbar-thumb {
  background-color: #d3d3d3;
  border-radius: 4px;
}

.popupQuestion::-webkit-scrollbar-thumb:hover {
  background-color: #a9a9a9;
}

.popupInput {
  width: 80%;
  height: 72px;
  flex-shrink: 0;
  border-radius: 11px;
  background: #384039;
  color: #FFF;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.25px;
  margin: 0 auto;
  margin-top: 40px;
  padding: 10px 20px;
  box-sizing: border-box;
  text-align: center;
  border: none;
}

.popupInput::placeholder {
  font-family: 'Roboto', sans-serif;
  text-align: center;
  font-size: 18px;
}

.popupSubmitButton {
  width: 330px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 20px;
  fill: #384039;
  background-color: #FFF;
  color: #384039;
  font-family: 'Audiowide', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.25px;
  margin: 0 auto;
  margin-top: 40px;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s ease;
}

.popupSubmitButton:hover {
  background-color: #384039;
  color: #FFF;
}


.Loader {
    height: 20vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading {
    height: 50px;
    width: 50px;
    border-radius: 50%;
  border: 10px solid #384039;
    border-top: 10px solid #1D1C2D;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}