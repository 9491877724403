
/* Loader */
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');
@import url('https://fonts.googleapis.com/css2?family=Orbitron&display=swap');
*, *:before, *:after {box-sizing:border-box;}


.range {
  margin-left: -50%;
  position: relative;
  background-color: #333;
  width: 600px;
  height: 30px;
  border-radius: 5px;
  font-family: 'Orbitron', monospace;
  
  &:before {
    --width: calc(var(--p) * 1%);
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: #0071f3;
    border-radius: 5px;
    z-index: 0;
    animation: load 0.1s forwards linear, infinite linear;
  }
  
  &:after {
    text-align: center;
    counter-reset: progress var(--p);
    content: counter(progress) '%';
    color: #000;
    position: absolute;
    left: 5%;
    font-size: 25px;
    font-weight: 1000;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
  .range__label {
  line-height: 1.5;
  position: absolute;
  top: -50px;
  color: #ccc;
  font-size: 30px;
}
  
}


@keyframes load {
  to {
    width: var(--width);
  }
}




